
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

































































































.hightlight-offer,
[class*='hightlight-offer--'] {
  padding: $spacing * 3.5 0 $spacing * 8.5;

  &.is-dark {
    background: $c-blue-dark;
  }

  &.is-light-gray {
    background-color: $c-light-gray;
  }

  @include mq(l) {
    padding: $spacing * 6 0;
  }
}

.hightlight-offer__inner {
  @include mq(l) {
    display: flex;
  }
}

.hightlight-offer__speedlines {
  @include get-all-space;

  @include mq($until: m) {
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
  }
}

.hightlight-offer__intro {
  margin-bottom: $spacing * 3.5;

  .is-dark & {
    color: $white;
  }

  @include mq(m) {
    width: col(10);
    margin-bottom: 0;
  }

  @include mq(l) {
    width: col(4);
    margin-right: col(1);
  }
}

.hightlight-offer__intro__title {
  margin: 0 0 $spacing * 1.25;

  @include mq(l) {
    margin: 0 0 $spacing * 2.75;
  }
}

.hightlight-offer__intro__text {
  margin: 0 0 $spacing * 2;

  @include mq(l) {
    margin: 0 0 $spacing * 4.25;
  }
}

.hightlight-offer__offer {
  position: relative;

  @include mq(l) {
    flex: 1;
  }
}

.hightlight-offer__offer__card {
  margin: 0 auto;
}
