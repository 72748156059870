
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        















































































.product-gamers-outer,
[class*='product-gamers-outer--'] {
  @include bg-color;

  position: relative;
  padding: $spacing * 2 0;

  @include mq('m') {
    padding: $spacing * 3 0;
  }

  @include mq('xl') {
    padding: $spacing * 6 0;
  }
}

.product-gamers {
  @include mq(l) {
    display: flex;
    align-items: center;
  }
}

.product-gamers__wrapp {
  @include mq(l) {
    width: col(5);
    padding-right: col(1);
  }
}

.product-gamers__content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.product-gamers__content__title {
  margin: 0;
  margin-top: $spacing;
}

.product-gamers__content__excerpt {
  @extend %fw-light;

  margin: 0;
  margin-top: $spacing * 1.5;
}

.product-gamers__content__include-outer {
  margin-top: $spacing * 2;

  @include mq(m) {
    margin-top: $spacing * 2.5;
  }
}

.product-gamers__content__include__excerpt {
  @extend %fw-light;

  margin-top: $spacing / 2;

  @include mq(m) {
    margin-top: $spacing;
  }
}

.product-gamers__speelines {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  margin-top: 0;

  @include mq($until: m) {
    display: none;
  }
}
