
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


















































































.marquee-row {
  position: relative;
  overflow: hidden;
  padding: 0 0 10rem;
  background-color: $c-blue-dark;
  --size: 32rem;
  --move-initial: 0;
  --move-final: calc((var(--size) + 5rem) * -2);

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    width: 16rem;
    height: 100%;
  }

  &::before {
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(18, 26, 40, 0) 20.34%,
      $c-blue-dark 100%
    );
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  &::after {
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(18, 26, 40, 0) 20.34%,
      $c-blue-dark 100%
    );
  }

  @include mq(m) {
    --size: 48rem;
  }
}

.marquee {
  position: relative;
  display: flex;
  width: fit-content;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 10s linear infinite;
  animation-play-state: running;

  &:hover {
    animation-play-state: paused;
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

.marquee-inner {
  display: flex;
}

.marquee__item {
  display: flex;
  align-items: center;
  width: var(--size);
  margin: 0 2.5rem;
  padding: 2.5rem;

  border: 1px solid #d3d3d3;
  border-radius: 20px;

  @include mq(m) {
    padding: 4rem;
  }
}

.marquee__item__icon {
  flex: 0 1 auto;
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;

  svg {
    fill: $white;
    width: 100%;
    height: auto;
  }

  @include mq(m) {
    width: 5rem;
    height: 5rem;
    margin-right: 3rem;
  }
}

.marquee__item__content {
  flex: 1;
  color: $white;
}

.marquee__item__label {
  font-size: 1.6rem;
  line-height: 1.5;

  @include mq(m) {
    font-size: 2.4rem;
  }
}

.marquee__item__number {
  font-size: 2.2rem;
  line-height: 1.2;
  text-transform: uppercase;

  b {
    font-size: 3rem;
  }

  @include mq(m) {
    font-size: 3.6rem;

    b {
      font-size: 4.8rem;
    }
  }
}
