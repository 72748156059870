
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        







































































































































































.gamers {
  ::v-deep {
    .centered-block__picture img {
      width: 5.5rem;
    }

    .gamers__speed {
      background-color: $c-blue-dark;
    }

    .marquee-row {
      background-color: $c-blue-dark;
    }
  }
}

.gamers__speed__label {
  text-transform: uppercase;
}
